// src/components/Blog.js
import React from 'react';

function Blog() {
    return (
        <div className="blog">
            <h1>Blog</h1>
            <p>Welcome to our blog page! Here you will find the latest articles. Coming soon!</p>
            {/* Add more blog content here */}
        </div>
    );
}

export default Blog;
