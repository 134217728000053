import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import './App.css';

// import Navbar from './components/Navbar.js';
import CallToAction from './components/CallToAction.js';
// import ServiceCard from './components/ServiceCard.js';
import ContactForm from './components/ContactForm.js';
import Blog from './components/Blog.js';
// import Navbar from './components/Navbar.js';

function App() {
  return (
    <Router>
      <div className="App">

        {/* <Navbar /> */}
        <div className="navbar">
          <Link to="/">Home</Link>
          <Link to="/blog">Blog</Link> {/* Blog button here */}
        </div>

        <div className="content">
          <Routes>
            <Route path="/" element={
              <>
                <div className="call-to-action-container">
                  <CallToAction />
                </div>
                <div className="contact-form-container">
                  <ContactForm />
                </div>
              </>
            } />
            <Route path="/blog" element={<Blog />} /> {/* Blog route */}
          </Routes>

        </div>

      </div>
    </Router>
  );
}

export default App;