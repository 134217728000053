import React from 'react';
import logo from '../AthenaLogoo-06.png'; // adjust the path as needed



function CallToAction() {
  return (
    <div className="call-to-action">
          <div><img src={logo} alt='logo' className="logo" /></div>
{/* Empowering women of color through Gen-AI to provide personalized, culturally relevant health and wellness solutions. */}
{/* <div style={{ fontSize: '18px', marginTop: '20px', fontWeight: '300' }}>Our mission is to address a significant global health challenge related to chronic 
inflammatory diseases and their impact on public health and age-related health decline, by allowing users 
to take control of their well-being using simple tools. </div> */}
</div>

  );
}



export default CallToAction;
