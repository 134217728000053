import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../App.css';


function ContactForm() {
  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    if (isSubmitted) {
      const timer = setTimeout(() => {
        setIsSubmitted(false);
      }, 5000); // 10000 ms = 10 seconds

      return () => clearTimeout(timer); // This will clear the timer when the component is unmounted
    }
  }, [isSubmitted]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // const response = await axios.post('http://localhost:3001/api/subscribe', { email });
      const response = await axios.post('/api/subscribe', { email });
      console.log(response);
      setIsSubmitted(true); // Set the submitted state to true
      // alert(`Email submitted: ${email}`);
      setEmail(''); // Clear the input field
    } catch (error) {
      alert('Error submitting email.');
      console.error(error);
      console.log(error.config.headers);
    }
  };

  return (
    <section className="contact-form">
      {/* <div style={{ marginBottom: '20px', fontSize: '20px', fontWeight: '400', textAlign: 'center' }}>Please subscribe to find out when we launch our platform</div> */}
      <div className="flex-container">
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button type="submit">Submit</button>
        </form>
      </div>
      <div className="chip" style={{ visibility: isSubmitted ? 'visible' : 'hidden' }}>Email submitted successfully!</div>
      <div style={{ color: '#2d4133', fontSize: '18px', textAlign: 'center', fontWeight: '500', lineHeight: '1.8' }}>Please subscribe to find out when we launch our platform<br></br> If you have any questions contact zahara@athenahealthai.uk</div>
      {/* <div style={{ marginTop: '40px', fontSize: '18px' }}>If you experience any issues contact info@immulab.app</div> */}
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div className="copyright">&copy; {new Date().getFullYear()} Athena Health AI</div>
      </div>
    </section>
  );
}

export default ContactForm;
